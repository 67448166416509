.assistant_page{
    background: rgba(249, 250, 251, 1);
    min-width: 100%;
    height: 100vh;
    display: flex;
    
}
.assistant_messages, .assistant_controls{
    
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}
.assistant_controls{
   
    background: #fff;
    height: calc(100% - 2px);
    border: 1px solid rgba(223, 224, 226, 1)
}
.assistant_logo{
    min-width: calc(100% - 40px);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    padding-bottom: 0px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}
.assistant_logo svg{
    margin-right: 8px;
}
.assistant_messages_inputs{
    min-width: calc(100% - 48px);
    height: fit-content;
    padding-left: 36px;
    padding-right: 12px;
    padding-bottom: 40px;
    position: absolute;
    bottom: 0;
}
.assistant_messages_body{
    width: 100%;
    height: calc(100% - 250px);
}
.assistant_messages_tags{
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 20px;
}
.assistant_messages_tag{
    width: calc(33% - 40px);
    height: fit-content;
    margin-right: 20px;
    background: #fff;
    border: 1px solid rgba(225, 228, 234, 1);
    padding: 10px;
    border-radius: 8px;

    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}
.assistant_messages_tag p{
    padding: 0px;
    margin: 0px;
    padding-top: 8px;
}
.assistant_messages_input{
    min-width: calc(100% - 16px);
    height: fit-content;
    background: rgba(237, 239, 242, 1);
    border-radius:26px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.assistant_messages_skreppole{
    min-width: calc(100% - 36px);
    display: flex;
    align-items: center;
}
.assistant_messages_skreppole svg{
    margin-left: 6px;
    margin-right: 16px;
}
.assistant_messages_skreppole textarea{
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 14.7px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    min-width: calc(100% - 56px);
    outline: none;
    border: 0px;
    background: none;
    resize: none;
    padding-top: 5px;
}
.assistant_messages_skreppole input::placeholder{
    color: rgba(2, 2, 3, 0.4);
    display: flex;
    align-items: center;
}
.assistant_messages_button{
    min-width: 36px;
    height: 36px;
    cursor: pointer;
}
.assistant_messages_button:hover svg rect{
    fill:rgba(9, 9, 9, 1)
}
.butt_resize{
    position: absolute;
    top: calc(50% - 15px);
    left: -15px;
    cursor: e-resize;
    z-index: 9999;
}
.reza{
    min-width: 100%;
}